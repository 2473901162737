import React, { useState } from 'react';
import styles from './HomePage.module.css';

const HomePage = () => {
  const [flashMessage, setFlashMessage] = useState('');

  const handleSubscribeClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setFlashMessage('Request feature via email.');
    setTimeout(() => setFlashMessage(''), 3000); // Hide the message after 3 seconds
  };

  return (
    <div className={styles.container}>
      {flashMessage && <div className={styles.flashMessage}>{flashMessage}</div>}
      <div className={styles.content}>
        <h1 className={styles.title}>Welcome to Purohit Family Tree</h1>
        <p className={styles.subtitle}>Discover the rich heritage and history of the Purohit family.</p>
        <button className={styles.exploreButton} onClick={() => window.location.href = '/tree'}>
          Explore Tree
        </button>
      </div>
      
      <div className={styles.facts}>
        <h2 className={styles.sectionTitle}>Family Information</h2>
        <ul className={styles.factList}>
          <li>Over 10+ generations recorded</li>
          <li>More than 100+ individuals connected</li>
          <li>Continuous project for generations to recognize & record legacy</li>
        </ul>
        <button className={styles.exploreButton} onClick={() => window.location.href = '/facts'}>
          Explore Facts
        </button>
      </div>
      <div className={styles.history}>
        <h2 className={styles.sectionTitle}>Historical Context of Gujarat</h2>
        <p className={styles.description}>
          The Purohit Family has a deep-rooted history in Gujarat, living through significant historical events and cultural shifts. From the establishment of the Gujarat Sultanate to the rise of Ahmedabad as a cultural center, the family's legacy intertwines with the region's rich heritage.
        </p>
        <button className={styles.exploreButton} onClick={() => window.location.href = '/explore'}>
          Explore Gujarat
        </button>
      </div>
      <div className={styles.facts}>
        <h2 className={styles.sectionTitle}>About The Project</h2>
        <ul className={styles.factList}>
          <li>Project is closed-source and contributions are welcome via email in footer</li>
          <li>Future prospects may include images, and individual anecdotes if support is garnered</li>
          <li>The tree source file alone so far is 3500+ lines of code, handwritten</li>
        </ul>
        <button className={styles.exploreButton} onClick={() => window.location.href = '/key'}>
          Learn Key
        </button>
      </div>
      <div className={styles.newsletter}>
        <h2 className={styles.newsletterTitle}>Signup for Maja Ni Waat Monthly Newsletter (મજા ની વાત)</h2>
        <form className={styles.newsletterForm} onSubmit={(e) => e.preventDefault()}>
          <input type="email" className={styles.newsletterInput} placeholder="Enter your email" />
          <button type="button" className={styles.newsletterButton} onClick={handleSubscribeClick}>Subscribe</button>
        </form>
      </div>
      <footer className={styles.footer}>
        Contact developer at <a href="mailto:jewellery-swathes-0r@icloud.com">jewellery-swathes-0r@icloud.com</a>
        <div className={styles.madeWithLove}>
          Made with ❤️ by ZP
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
