import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './KeyPage.module.css';

const KeyPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          &larr; Back
        </button>
      </div>
      <h1 className={styles.title}>Understanding the Purohit Family Tree</h1>
      <div className={styles.content}>
        <div className={styles.keyItem}>
          <span className={styles.keyTitle}>Males:</span> <span className={styles.maleColor}>Blue</span>
        </div>
        <div className={styles.keyItem}>
          <span className={styles.keyTitle}>Females:</span> <span className={styles.femaleColor}>Pink</span>
        </div>
        <div className={styles.keyItem}>
          <span className={styles.keyTitle}>Inclusion Criteria:</span> Any individual crossing age 5 is included
        </div>
        <div className={styles.keyItem}>
          <span className={styles.keyTitle}>Box Click:</span> Clicking a box will open information about siblings, parents, spouses, and children
        </div>
        <div className={styles.keyItem}>
          <span className={styles.keyTitle}>Clickable Names:</span> Names in information box are clickable and map to the tree
        </div>
        <div className={styles.keyItem}>
          <span className={styles.keyTitle}>Zoom:</span> On some laptops, two finger zoom forward & back works better than pinch
        </div>
        <div className={styles.keyItem}>
          <span className={styles.keyTitle}>Best Use Case:</span> While compatible on mobile, the best use case is on laptop, or iPads/tablets
        </div>
      </div>
    </div>
  );
};

export default KeyPage;
