import React, { useMemo, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // Update import to use `Routes`, `Route`, and `useNavigate`
import type { Node, ExtNode } from 'relatives-tree/lib/types';
import ReactFamilyTree from 'react-family-tree';
import { SourceSelect } from '../SourceSelect/SourceSelect';
import { PinchZoomPan } from '../PinchZoomPan/PinchZoomPan';
import { FamilyNode } from '../FamilyNode/FamilyNode';
import { NodeDetails } from '../NodeDetails/NodeDetails';
import { NODE_WIDTH, NODE_HEIGHT, SOURCES, DEFAULT_SOURCE } from '../const';
import { getNodeStyle } from './utils';
import HomePage from '../HomePage/HomePage';
import ExploreGujarat from '../ExploreGujarat/ExploreGujarat';
import Facts from '../Facts/Facts';

import css from './App.module.css';
import styles from './App.module.css';
import KeyPage from '../KeyPage/KeyPage';

const FamilyTreeApp = () => {
  const [source, setSource] = useState(DEFAULT_SOURCE);
  const [nodes, setNodes] = useState(SOURCES[source]);

  const firstNodeId = useMemo(() => nodes[0].id, [nodes]);
  const [rootId, setRootId] = useState(firstNodeId);

  const [selectId, setSelectId] = useState<string>();
  const [hoverId, setHoverId] = useState<string>();

  const resetRootHandler = useCallback(() => setRootId(firstNodeId), [firstNodeId]);

  const changeSourceHandler = useCallback(
    (value: string, nodes: readonly Readonly<Node>[]) => {
      setRootId(nodes[0].id);
      setNodes(nodes);
      setSource(value);
      setSelectId(undefined);
      setHoverId(undefined);
    },
    [],
  );

  const selected = useMemo(() => (
    nodes.find(item => item.id === selectId)
  ), [nodes, selectId]);

  const navigate = useNavigate();

  return (
    <div className={css.root}>
      <header className={css.header}>
        <h1 className={css.title}>
          Purohit Family Tree
          <span className={css.version}>
            by ZP
          </span>
        </h1>

        <div className={styles.hidden}>
          <label className={styles.hidden}>Source: </label>
          <SourceSelect value={source} items={SOURCES} onChange={changeSourceHandler} />
        </div>

        <button className={css.backButton} onClick={() => navigate(-1)}>
          &larr; Back
        </button>
      </header>
      {nodes.length > 0 && (
        <PinchZoomPan min={0.5} max={2.5} captureWheel className={css.wrapper}>
          <ReactFamilyTree
            nodes={nodes}
            rootId={rootId}
            width={NODE_WIDTH}
            height={NODE_HEIGHT}
            className={css.tree}
            renderNode={(node: Readonly<ExtNode>) => (
              <FamilyNode
                key={node.id}
                node={node}
                isRoot={node.id === rootId}
                isHover={node.id === hoverId}
                onClick={setSelectId}
                onSubClick={setRootId}
                style={getNodeStyle(node)}
              />
            )}
          />
        </PinchZoomPan>
      )}
      {rootId !== firstNodeId && (
        <button className={css.reset} onClick={resetRootHandler}>
          Reset
        </button>
      )}
      {selected && (
        <NodeDetails
          node={selected}
          className={css.details}
          onSelect={setSelectId}
          onHover={setHoverId}
          onClear={() => setHoverId(undefined)}
        />
      )}
    </div>
  );
}

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tree" element={<FamilyTreeApp />} />
        <Route path="/explore" element={<ExploreGujarat />} />
        <Route path="/facts" element={<Facts />} />
        <Route path="/key" element={<KeyPage />} />
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
