import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Facts.module.css';

const Facts = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          &larr; Back
        </button>
      </div>
      <h1 className={styles.title}>Purohit Family Records</h1>
      <p className={styles.intro}>Discover the diverse professions, achievements, and businesses owned by the Purohit family.</p>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Professions Traversed</h2>
        <div className={styles.cards}>
          <div className={styles.card}>Teachers</div>
          <div className={styles.card}>Principals</div>
          <div className={styles.card}>Bankers</div>
          <div className={styles.card}>Engineers</div>
          <div className={styles.card}>Dancers</div>
          <div className={styles.card}>Poets</div>
          <div className={styles.card}>Writers</div>
          <div className={styles.card}>Army Officer</div>
          <div className={styles.card}>Software Engineers</div>
          <div className={styles.card}>Scuba Instructor</div>
          <div className={styles.card}>Entrepreneurs</div>
          <div className={styles.card}>Doctors</div>
          <div className={styles.card}>Architects</div>
          <div className={styles.card}>Dentists</div>
        </div>
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Accolades Gathered</h2>
        <div className={styles.cards}>
          <div className={styles.card}>International Dance Awards</div>
          <div className={styles.card}>Army Major General Rank</div>
          <div className={styles.card}>Sena Medal</div>
          <div className={styles.card}>AVSM Medal</div>
          <div className={styles.card}>VSM Medal</div>
          <div className={styles.card}>School House Captain</div>
          <div className={styles.card}>Platinum Scuba Instructor Rating</div>
          <div className={styles.card}>Top University Graduates</div>
          <div className={styles.card}>Successful Entrepreneurs</div>
          <div className={styles.card}>Published Authors</div>
          <div className={styles.card}>Renowned Artists</div>
          <div className={styles.card}>Patent Holders</div>
          <div className={styles.card}>Community Leaders</div>
          <div className={styles.card}>Parliamentary Debater</div>
        </div>
      </div>
     
    </div>
  );
};

export default Facts;
