import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ExploreGujarat.module.css';

// Import images
import mapOfGujarat from './mapofgujarat.png';
import garbaImage from './garba.jpg';
import rannOfKutch from './rannofkutch.jpg';

const ExploreGujarat = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          &larr; Back
        </button>
      </div>
      <h1 className={styles.title}>Explore Gujarat</h1>
      <p className={styles.intro}>Gujarat, known for its diverse culture and rich history, offers numerous attractions and experiences. Here are some key highlights:</p>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Geography</h2>
        <p>Gujarat is located in the western part of India, bordered by Pakistan to the northwest, the Arabian Sea to the southwest, and the states of Rajasthan, Madhya Pradesh, and Maharashtra. It has a varied landscape that includes coastal plains, mountain ranges, and fertile agricultural lands.</p>
        <img src={mapOfGujarat} alt="Map of Gujarat" className={styles.image} />
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Historical Sites</h2>
        <ul className={styles.list}>
          <li><strong>Somnath Temple</strong>: One of the twelve Jyotirlinga shrines of Shiva, known for its architectural grandeur.</li>
          <li><strong>Rani ki Vav</strong>: An intricately constructed stepwell in Patan, a UNESCO World Heritage Site.</li>
          <li><strong>Gir National Park</strong>: The only place in the world where Asiatic lions can be found in the wild.</li>
          <li><strong>Dwarka</strong>: An ancient city associated with Lord Krishna, known for its temples and archaeological significance.</li>
        </ul>
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Culture and Festivals</h2>
        <p>Gujarat is famous for its vibrant festivals, including Navratri, celebrated with Garba and Dandiya Raas dances, and the Kite Festival, which fills the skies with colorful kites.</p>
        <img src={garbaImage} alt="Garba dance" className={styles.image} />
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Economy and Industry</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Sector</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Textiles</td>
              <td>Gujarat is a leading textile manufacturer in India, known for its cotton production.</td>
            </tr>
            <tr>
              <td>Petrochemicals</td>
              <td>The state is home to major oil refineries and chemical industries.</td>
            </tr>
            <tr>
              <td>Automobiles</td>
              <td>Gujarat hosts several automobile manufacturing plants, including those of Tata Motors and Maruti Suzuki.</td>
            </tr>
            <tr>
              <td>Ports</td>
              <td>With a long coastline, Gujarat has several major ports facilitating trade and commerce.</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Natural Wonders</h2>
        <p>Gujarat's natural beauty includes the Great Rann of Kutch, a seasonal salt marsh, and the Saputara Hill Station, known for its scenic landscapes and pleasant climate.</p>
        <img src={rannOfKutch} alt="Rann of Kutch" className={styles.image} />
      </div>
      
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Further Reading</h2>
        <ul className={styles.list}>
          <li><a href="https://www.gujarattourism.com/" target="_blank" rel="noopener noreferrer">Gujarat Tourism Official Website</a></li>
          <li><a href="https://en.wikipedia.org/wiki/Gujarat" target="_blank" rel="noopener noreferrer">Gujarat - Wikipedia</a></li>
          <li><a href="https://www.culturalindia.net/indian-history/ancient-history/gujarat.html" target="_blank" rel="noopener noreferrer">Ancient History of Gujarat</a></li>
        </ul>
      </div>
    </div>
  );
};

export default ExploreGujarat;
